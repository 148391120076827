import {computed} from "vue"
import {useStore} from "vuex"

const useFacturas = () => {
  const store = useStore()

  const getFacturas = async () => {
    return await store.dispatch("facturas/getFacturas")
  }

  const setValidarXML = async (datos) => {
    return await store.dispatch("facturas/setValidarXML", datos)
  }

  const setFacturar = async (datos) => {
    return await store.dispatch("facturas/setFacturar", datos)
  }

  const setSubirDocumentoFactura = async (datos) => {
    return await store.dispatch("facturas/setSubirDocumentoFactura", datos)
  }

  const formaDetalleIni = {
    archivoXml: null,
    archivoPdf: null,
    factura: "",
    fecha: "",
    detalle: "",
  }

  const formaDocumentoFacturaIni = {
    detalle: "",
    documento: "",
    archivo: null,
  }

  return {
    formaDetalleIni,
    formaDocumentoFacturaIni,
    facturas: computed(() => store.getters["facturas/facturas"]),
    estatus: computed(() => store.getters["facturas/estatus"]),
    validando: computed(() => store.getters["facturas/validando"]),
    guardando: computed(() => store.getters["facturas/guardando"]),
    subiendo: computed(() => store.getters["facturas/subiendo"]),
    getFacturas,
    setValidarXML,
    setFacturar,
    setSubirDocumentoFactura,
  }
}

export default useFacturas
