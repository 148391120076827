<template>
  <div class="modal-background fade-in">
    <div class="modal-container" :style="{ width: w, height: h }">
      <slot name="header" />
      <slot name="body" />
      <slot name="footer" />

      <slot name="exposed" newTitle="newTitle" hola="mundo"></slot>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
export default {
  name: "Modal",
  emits: ["on:close"],
  props: ["width", "height"],
  setup(props, context) {
    const wIni = "550px";
    const hIni = "550px";
    const w = ref(wIni);
    const h = ref(hIni);

    if (props.width) {
      w.value = props.width;
    }

    if (props.height) {
      h.value = props.height;
    }

    watch(
      () => props.width,
      () => {
        if (props.width) {
          w.value = props.width;
        }
      }
    );

    watch(
      () => props.height,
      () => {
        if (props.height) {
          h.value = props.height;
        }
      }
    );

    return { w, h };
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-container {
  width: 550px;
  height: 550px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  color: red;
}

.fade-in {
  animation: fadeIn ease-out 0.2s;
  -webkit-animation: fadeIn ease-out 0.2s;
  -moz-animation: fadeIn ease-out 0.2s;
  -o-animation: fadeIn ease-out 0.2s;
  -ms-animation: fadeIn ease-out 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>